import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"

const connectionString = (process.env.NODE_ENV === 'production')
    ? 'InstrumentationKey=97c2ee20-3681-4457-b63a-c84594bfaba6;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=kixvu.com'
    : 'InstrumentationKey=28c427c0-d7d7-4d5e-956a-c06369e48500;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=kixvu.com'

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    }
});

appInsights.loadAppInsights();

export default reactPlugin
